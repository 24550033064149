import Ui from "./CodeGenerator/Ui";

function App() {
  return (
    <>
      <Ui />
    </>
  );
}

export default App;
