import React, { useState } from "react";
import "./css/style.css";
import "./css/cursor.css";
import axios from "axios";
import API_URL from "../config";
import "./js/cursor.js";
const Ui = () => {
  const params = {
    repository_name: "",
    eloquent_name: "",
    model_name: "",
    namespace_name: "",
    all: "",
    find: "",
    paginate: "",
    where: "",
    create: "",
    update: "",
    delete: "",
  };
  const [formData, setFormData] = useState({ params });
  const [responseError, setResponseError] = useState({});
  const [finalResponse, setFinalResponse] = useState({});
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const handleData = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleImageDownload = (file_name) => {
    window.open(API_URL + "download/" + file_name);
  };
  const createCode = async (e) => {
    e.preventDefault();
    await axios
      .post(API_URL + "code-generate", formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          setShowDownloadButton(true);
          setFinalResponse(response.data.data.codeGenerated);
          setResponseError({});
          setFormData(params);
          e.target.reset();
        }
      })
      .catch(function (error) {
        setResponseError(error.response.data.errors);
        setShowDownloadButton(false);
      });
  };

  const closePopUp = () => {
    setShowDownloadButton(false);
    setResponseError({});
    setFormData(params);
  };
  return (
    <>
      {showDownloadButton ? (
        <div
          className="modal fade show bg-dark"
          id="exampleModalCenter"
          tabindex="-1"
          aria-labelledby="exampleModalCenterTitle"
          aria-modal="true"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header" style={{ border: "none" }}>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={closePopUp}
                ></button>
              </div>
              <div className="modal-body py-5 d-flex justify-content-center">
                <button
                  href={finalResponse.repository_file_path}
                  className="btn  btn-lg mybtn mx-2"
                  onClick={() =>
                    handleImageDownload(finalResponse.repository_file_name)
                  }
                >
                  Download Repository
                </button>
                <button
                  href={finalResponse.eloquent_file_path}
                  className="btn mybtn btn-lg"
                  onClick={() =>
                    handleImageDownload(finalResponse.eloquent_file_name)
                  }
                >
                  Download Eloquent
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <section className="container-fluid background-radial-gradient w-100">
        <div className="container  d-flex  align-items-center ">
          <div className="row">
            <div className="col-12 ">
              <div
                className="card text-black py-4"
                style={{ borderRadius: "25px" }}
              >
                <div className="card-body p-md-5">
                  <div className="row justify-content-center">
                    <div className="col-md-7 order-2 order-lg-1">
                      <form className="mx-1 mx-md-4" onSubmit={createCode}>
                        <div className="row mb-4">
                          <div className="form-outline col-md-6">
                            <label
                              className="form-label"
                              htmlFor="repository_name"
                            >
                              Repository Name
                            </label>
                            <input
                              type="text"
                              id="repository_name"
                              name="repository_name"
                              placeholder="TestRepository"
                              className="form-control rounded-pill"
                              onChange={handleData}
                              value={formData.repository_name}
                            />
                            {responseError.repository_name ? (
                              <span className="text-danger">
                                {responseError.repository_name[0]}
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="form-outline col-md-6">
                            <label
                              className="form-label"
                              htmlFor="eloquent_name"
                            >
                              Eloquent Name
                            </label>
                            <input
                              type="text"
                              id="eloquent_name"
                              name="eloquent_name"
                              placeholder="TestEloquent"
                              className="form-control rounded-pill"
                              onChange={handleData}
                              value={formData.eloquent_name}
                            />
                            {responseError.eloquent_name ? (
                              <span className="text-danger">
                                {responseError.eloquent_name[0]}
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="form-outline col-md-6">
                            <label className="form-label" htmlFor="model_name">
                              Model Name
                            </label>
                            <input
                              type="text"
                              id="model_name"
                              name="model_name"
                              placeholder="Test"
                              className="form-control rounded-pill"
                              onChange={handleData}
                              value={formData.model_name}
                            />
                            {responseError.model_name ? (
                              <span className="text-danger">
                                {responseError.model_name[0]}
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="form-outline col-md-6">
                            <label
                              className="form-label"
                              htmlFor="namespace_name"
                            >
                              NameSpace Name
                            </label>
                            <input
                              type="text"
                              id="namespace_name"
                              name="namespace_name"
                              placeholder="App\Repository\Test"
                              className="form-control rounded-pill"
                              onChange={handleData}
                              value={formData.namespace_name}
                            />
                            {responseError.namespace_name ? (
                              <span className="text-danger">
                                {responseError.namespace_name[0]}
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div
                          className="row mb-4"
                          style={{
                            paddingLeft: "calc(var(--bs-gutter-x) * .5)",
                          }}
                        >
                          <div className="form-check col">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={handleData}
                              id="all"
                              value="all"
                              name="all"
                            />
                            <label className="form-check-label" htmlFor="all">
                              All
                            </label>
                          </div>
                          <div className="form-check col">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={handleData}
                              id="find"
                              value="find"
                              name="find"
                            />
                            <label className="form-check-label" htmlFor="find">
                              Find
                            </label>
                          </div>
                          <div className="form-check col">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={handleData}
                              id="paginate"
                              value="paginate"
                              name="paginate"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="paginate"
                            >
                              Paginate
                            </label>
                          </div>
                          <div className="form-check col">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={handleData}
                              id="where"
                              value="where"
                              name="where"
                            />
                            <label className="form-check-label" htmlFor="where">
                              Where
                            </label>
                          </div>
                          <div className="form-check col">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={handleData}
                              id="create"
                              value="create"
                              name="create"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="create"
                            >
                              Create
                            </label>
                          </div>
                          <div className="form-check col">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={handleData}
                              id="update"
                              value="update"
                              name="update"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="update"
                            >
                              Update
                            </label>
                          </div>
                          <div className="form-check col">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={handleData}
                              id="delete"
                              value="delete"
                              name="delete"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="delete"
                            >
                              Delete
                            </label>
                          </div>
                        </div>

                        <div className="float-start">
                          <button
                            type="submit"
                            className="btn btn-custom btn-lg text-white"
                          >
                            Create Code
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="col-md-5 d-flex align-items-center order-1 order-lg-2">
                      <img src={"logo.jpg"} className="img-custom" alt="logo" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Ui;
